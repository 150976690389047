
@font-face {
    font-family: 'robotoblack';
    font-display: swap;
    src: url('../fonts/roboto-black-webfont.eot');
    src: url('../fonts/roboto-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-black-webfont.woff2') format('woff2'),
         url('../fonts/roboto-black-webfont.woff') format('woff'),
         url('../fonts/roboto-black-webfont.ttf') format('truetype'),
         url('../fonts/roboto-black-webfont.svg#robotoblack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoregular';
    font-display: swap;
    src: url('../fonts/roboto-regular-webfont.eot');
    src: url('../fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff'),
         url('../fonts/roboto-regular-webfont.ttf') format('truetype'),
         url('../fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotobold';
    font-display: swap;
    src: url('../fonts/roboto-bold-webfont.eot');
    src: url('../fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff'),
         url('../fonts/roboto-bold-webfont.ttf') format('truetype'),
         url('../fonts/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotomedium';
    font-display: swap;
    src: url('../fonts/roboto-medium-webfont.eot');
    src: url('../fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto-medium-webfont.woff') format('woff'),
         url('../fonts/roboto-medium-webfont.ttf') format('truetype'),
         url('../fonts/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotolight';
    font-display: swap;
    src: url('../fonts/roboto-light-webfont.eot');
    src: url('../fonts/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-light-webfont.woff2') format('woff2'),
         url('../fonts/roboto-light-webfont.woff') format('woff'),
         url('../fonts/roboto-light-webfont.ttf') format('truetype'),
         url('../fonts/roboto-light-webfont.svg#robotolight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotolight_italic';
    font-display: swap;
    src: url('../fonts/roboto-lightitalic-webfont.eot');
    src: url('../fonts/roboto-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-lightitalic-webfont.woff') format('woff'),
         url('../fonts/roboto-lightitalic-webfont.ttf') format('truetype'),
         url('../fonts/roboto-lightitalic-webfont.svg#robotolight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto_condensedlight';
    font-display: swap;
    src: url('../fonts/robotocondensed-light-webfont.eot');
    src: url('../fonts/robotocondensed-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/robotocondensed-light-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-light-webfont.woff') format('woff'),
         url('../fonts/robotocondensed-light-webfont.ttf') format('truetype'),
         url('../fonts/robotocondensed-light-webfont.svg#roboto_condensedlight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto_condensedregular';
    font-display: swap;
    src: url('../fonts/robotocondensed-regular-webfont.eot');
    src: url('../fonts/robotocondensed-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/robotocondensed-regular-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-regular-webfont.woff') format('woff'),
         url('../fonts/robotocondensed-regular-webfont.ttf') format('truetype'),
         url('../fonts/robotocondensed-regular-webfont.svg#roboto_condensedregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto_condensedbold';
    font-display: swap;
    src: url('../fonts/robotocondensed-bold-webfont.eot');
    src: url('../fonts/robotocondensed-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/robotocondensed-bold-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-bold-webfont.woff') format('woff'),
         url('../fonts/robotocondensed-bold-webfont.ttf') format('truetype'),
         url('../fonts/robotocondensed-bold-webfont.svg#roboto_condensedbold') format('svg');
    font-weight: normal;
    font-style: normal;

}


/***************************** BDIGITAL BANKINTER *******************************/

@font-face {
    font-family: "Bankinter Regular";
    src: url('../fonts/BdigitalBankinter/BankinterSans-Reg.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


/***************************** SMART CULTURE *******************************/

@font-face {
    font-family: "SEGOE UI LIGHT";
    src: url('../fonts/SmartCulture/segoeuil.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "SEGOE UI BLACK";
    src: url('../fonts/SmartCulture/seguibl.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


/***************************** RANK EN RACHA CAMPUS *******************************/

@font-face {
    font-family: "Gotham Book";
    src: url('../fonts/EnrachaCampus/Gotham-Book.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/***************************** MWC *******************************/

@font-face {
    font-family: "Graphik Regular";
    src: url('../fonts/MWC/Graphik-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/***************************** FIN MWC *******************************/

/***************************** Fotocasa *******************************/

@font-face {
    font-family: "OpenSans Regular";
    src: url('../fonts/Fotocasa/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/***************************** FIN Fotocasa *******************************/

/***************************** Sdgencat *******************************/

@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/Sdgencat/opensans-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansLight';
    src: url('../fonts/Sdgencat/opensans-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansSemibold';
    src: url('../fonts/Sdgencat/opensans-semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansBold';
    src: url('../fonts/Sdgencat/opensans-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


/***************************** FIN Sdgencat *******************************/

/***************************** WESTTLECO *******************************/

@font-face {
    font-family: "SansPro Regular";
    src: url('../fonts/Westtelco/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/***************************** FIN WESTTLECO *******************************/

/***************************** GROWARE *******************************/

@font-face {
    font-family: "Montserrat Regular";
    src: url('../fonts/Groware/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/***************************** FIN GROWARE *******************************/

/***************************** Blanc!TV *******************************/

@font-face {
    font-family: "monotype_font2";
    src: url('../fonts/BlancTV/monotype_blanctv.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/***************************** FIN Blanc!TV *******************************/

/***************************** TINKLE *******************************/

@font-face {
    font-family: 'Rajdhani-bold';
    src: url('../fonts/TINKLE/Rajdhani/Rajdhani-Bold.ttf')  format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani-light';
    src: url('../fonts/TINKLE/Rajdhani/Rajdhani-Light.ttf')  format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani-medium';
    src: url('../fonts/TINKLE/Rajdhani/Rajdhani-Medium.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani-regular';
    src: url('../fonts/TINKLE/Rajdhani/Rajdhani-Regular.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani-semibold';
    src: url('../fonts/TINKLE/Rajdhani/Rajdhani-SemiBold.ttf')  format('truetype');
    font-weight: bold;
    font-style: normal;
}

/***************************** FIN Tinkle *******************************/

@font-face {
    font-family: "Inter Bold";
    src: url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter Regular";
    src: url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* ******  ORANGE ****** */
@font-face {
    font-family: "Helvetica-Regular";
    src: url('../fonts/Orange/helveticaneue.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica--Neue--55";
    src: url('../fonts/Orange/helvetica-neue-55.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Helvetica--Neue--75";
    src: url('../fonts/Orange/helvetica-neue-75.ttf')  format('truetype');
    font-weight: bold;
    font-style: normal;
}

/********************* BBVA *********************/
@font-face {
    font-family: "BentonSansRegular";
    src: url('../fonts/BBVA/BentonSansBBVA-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "BentonSansBold";
    src: url('../fonts/BBVA/BBVABentonSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "BentonSansLight";
    src: url('../fonts/BBVA/BBVABentonSansLight-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "BentonSansLightBold";
    src: url('../fonts/BBVA/BBVABentonSansLight-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "BentonSansBook";
    src: url('../fonts/BBVA/BBVABentonSans-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/********************* PASAPORTEIA *********************/

@font-face {
    font-family: 'RoundedMPlusBlack';
    src: url('../fonts/PasaporteIA/rounded-mplus-2p-black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RoundedMPlusBold';
    src: url('../fonts/PasaporteIA/rounded-mplus-2p-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RoundedMPlusHeavy';
    src: url('../fonts/PasaporteIA/rounded-mplus-2p-heavy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RoundedMPlusLight';
    src: url('../fonts/PasaporteIA/rounded-mplus-2p-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RoundedMPlusMedium';
    src: url('../fonts/PasaporteIA/rounded-mplus-2p-medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RoundedMPlusRegular';
    src: url('../fonts/PasaporteIA/rounded-mplus-2p-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'RoundedMPlusThin';
    src: url('../fonts/PasaporteIA/rounded-mplus-2p-thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}